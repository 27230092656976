import { defineMessages } from 'react-intl';

const messages = defineMessages({
  availableFields: {
    id: 'Available Fields',
    defaultMessage: 'Available Fields',
  },
  availableFieldTitle: {
    id: 'availableFieldTitle',
    defaultMessage: 'Title',
  },
  availableFieldDescription: {
    id: 'availableFieldDescription',
    defaultMessage: 'Description',
  },
  availableFieldAuthors: {
    id: 'availableFieldAuthors',
    defaultMessage: 'Authors',
  },
  availableFieldDate: {
    id: 'availableFieldDate',
    defaultMessage: 'Date',
  },
  availableFieldTags: {
    id: 'availableFieldTags',
    defaultMessage: 'Tags',
  },
  availableFieldImage: {
    id: 'availableFieldImage',
    defaultMessage: 'Image',
  },
  availableFieldEventStart: {
    id: 'availableFieldEventStart',
    defaultMessage: 'Event Start',
  },
  availableFieldEventEnd: {
    id: 'availableFieldEventEnd',
    defaultMessage: 'Event End',
  },
});

// Define choices directly to verify if they are added correctly
const getAvailableFieldChoices = (intl) => [
  ['title', intl.formatMessage(messages.availableFieldTitle)],
  ['description', intl.formatMessage(messages.availableFieldDescription)],
  ['listCreators', intl.formatMessage(messages.availableFieldAuthors)],
  ['effective', intl.formatMessage(messages.availableFieldDate)],
  ['Subject', intl.formatMessage(messages.availableFieldTags)],
  ['lead_image', intl.formatMessage(messages.availableFieldImage)],
  ['start', intl.formatMessage(messages.availableFieldEventStart)],
  ['end', intl.formatMessage(messages.availableFieldEventEnd)],
];
export const getAvailableFieldsProperty = (intl) => {
  return {
    title: intl.formatMessage(messages.availableFields),
    description: 'Select the fields to be displayed in the Detailed View',
    choices: getAvailableFieldChoices(intl),
    widget: 'array',
  };
};
