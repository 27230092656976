import React from 'react';
import PropTypes from 'prop-types';
import { ConditionalLink, Component } from '@plone/volto/components';
import { flattenToAppURL } from '@plone/volto/helpers';
import { isInternalURL } from '@plone/volto/helpers/Url/Url';
import { format } from 'date-fns';

const DetailedTemplate = ({ items, linkTitle, linkHref, isEditMode, availableFields }) => {
  let link = null;
  let href = linkHref?.[0]?.['@id'] || '';

  if (typeof(availableFields) == 'undefined' && !availableFields) {
    availableFields = ['title', 'description'];
  }

  if (isInternalURL(href)) {
    link = (
      <ConditionalLink to={flattenToAppURL(href)} condition={!isEditMode}>
        {linkTitle || href}
      </ConditionalLink>
    );
  } else if (href) {
    link = <a href={href}>{linkTitle || href}</a>;
  }

  let formatted_objects = items.map((item) => {
        const formattedObj = {};

        for (const [key, value] of Object.entries(item)) {
            // Same formatting logic as above
            switch(key) {
                case 'effective':
                    if (value) {
                      let eff_date = new Date(value);
                      if (eff_date.getFullYear() == '1969') {
                        formattedObj[key] = ""
                      } else {
                        formattedObj[key] = format(eff_date, 'MMM d, yyyy');
                      };
                      break;
                    }
                case 'listCreators':
                case 'Subject':
                    let result = value;
                    if (Array.isArray(value) && value.length == 0) {
                      result = "";
                    } else if (Array.isArray(value) && value.length>1) {
                      result = value.join(", ");
                    };
                    formattedObj[key] = result;
                    break;
                default:
                    formattedObj[key] = value;
            }
        }

        formattedObj.eventdates = ""
        if ('start' in formattedObj && 'end' in formattedObj && formattedObj.start && formattedObj.end) {
          let start = new Date(formattedObj['start']);
          let end = new Date(formattedObj['end']);
          if (start.toDateString() == end.toDateString()) {
            formattedObj.eventdates = format(start, 'MMM d, yyyy');
          } else {
            formattedObj.eventdates = format(start, 'MMM d, yyyy') + ' - ' + format(end, 'MMM d, yyyy');
          }
        } 
        return formattedObj;
    });

  return (
      <div className="items">
        {formatted_objects.map((item) => (
          <div className="detailed listing-item" key={item['@id']}>
            <ConditionalLink item={item} condition={!isEditMode} target='blank'>
              {availableFields.includes('lead_image') && item.image_field && <Component componentName="PreviewImage" item={item} alt="item.title" />}
              <div className="listing-body">
                {availableFields.includes('title') && <h3>{item.title}</h3>}
                {availableFields.includes('description') && <p>{item.description}</p>}
                {availableFields.includes('listCreators') && item.listCreators && <p className="authors"><strong>Authors: </strong>{item.listCreators}</p>}
                {availableFields.includes('effective') && item.effective && <p className="effective-date"><strong>Publication Date: </strong>{item.effective}</p>}
                {(availableFields.includes('start') || availableFields.includes('end')) && item.eventdates && <p className="event-dates"><strong>Event Dates: </strong>{item.eventdates}</p>}
                {availableFields.includes('Subject') && item.Subject && <p className="tags"><strong>Tags: </strong>{item.Subject}</p>}
              </div>
            </ConditionalLink>
          </div>
        ))}
      </div>
  );
};

DetailedTemplate.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  linkMore: PropTypes.any,
  isEditMode: PropTypes.bool,
};

export default DetailedTemplate;
