// SemanticUI-free pre-@plone/components
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Container, List, Grid, GridColumn } from 'semantic-ui-react';
import MobileNavigation from '../MobileNavigation/MobileNavigation';
import { useIntl, defineMessages } from 'react-intl';
import config from '@plone/volto/registry';
import cx from 'classnames';
import IntranetSearchWidget from '../SearchWidget/IntranetSearchWidget';

import Anontools from '@plone/volto/components/theme/Anontools/Anontools';
import LanguageSelector from '@plone/volto/components/theme/LanguageSelector/LanguageSelector';
import Logo from '@plone/volto/components/theme/Logo/Logo';
import Navigation from '@plone/volto/components/theme/Navigation/Navigation';
import SearchWidget from '@plone/volto/components/theme/SearchWidget/SearchWidget';
import UniversalLink from '@plone/volto/components/manage/UniversalLink/UniversalLink';

import SlotRenderer from '@plone/volto/components/theme/SlotRenderer/SlotRenderer';

const messages = defineMessages({
  siteLabel: {
    id: 'siteLabel',
    defaultMessage: ' ',
  },
});

const InternetHeader = ({ pathname, siteLabel, token, siteAction }) => {
  return (
    <>
      <div className="header">
        <div className="logo-nav-wrapper">
          <div className="logo">
            <Logo />
          </div>
          <Navigation pathname={pathname} />
          <MobileNavigation pathname={pathname} />
          <div className="search-wrapper navigation-desktop">
            <div className="search">
              <SearchWidget />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const IntranetHeader = ({ pathname, siteLabel, token, siteAction }) => {
  return (
    <>
      <div className="header">
        <div className="tools-wrapper">
          <LanguageSelector />

          <div className="tools">
            {!token && <Anontools />}
            {siteAction &&
              siteAction.map((item) => (
                <UniversalLink key={item.url} href={item.url}>
                  {item.title}
                </UniversalLink>
              ))}
          </div>
          {siteLabel && (
            <div className="intranet">
              <p>{siteLabel}</p>
            </div>
          )}
        </div>
        <div className="logo-nav-wrapper">
          <div className="logo">
            <Logo />
          </div>
          <div className="search-wrapper">
            <div className="search">
              <IntranetSearchWidget />
            </div>
          </div>
          <Navigation pathname={pathname} />
          <MobileNavigation pathname={pathname} />
        </div>
      </div>
    </>
  );
};

const Header = (props) => {
  const { pathname } = props;
  let siteLabel = config.settings.siteLabel;
  const intranetHeader = config.settings.intranetHeader;
  const token = useSelector((state) => state.userSession.token);
  const content = useSelector((state) => state.content.data);
  const siteAction = useSelector(
    (state) => state.content.data?.['@components']?.actions?.site_actions,
  );
  const navRoot = useSelector((state) => state.navroot?.data?.navroot);
  const intl = useIntl();
  const translatedSiteLabel = intl.formatMessage(messages.siteLabel);

  siteLabel =
    siteLabel &&
    (translatedSiteLabel !== 'siteLabel' && translatedSiteLabel !== ' '
      ? translatedSiteLabel
      : siteLabel);

  return (
    <>
      <SlotRenderer name="aboveHeader" content={content} navRoot={navRoot} />
      <header
        className={cx('header-wrapper', { 'intranet-header': intranetHeader })}
      >
        <div class="scroll-watcher"></div>
        <div className="introHeader">
          <Container layout>
            <Grid columns={2}>
              <GridColumn textAlign="left">
                <span class="address">32 Dullah Omar Lane, Durban</span>
                <span class="email">
                  <a href="mailto:info@ddp.org.za">info@ddp.org.za</a>
                </span>
              </GridColumn>
              <GridColumn textAlign="right">
                <span class="number">
                  <a href="tel:+27313062261">Contact: +27 31 304 9305</a>
                </span>
                <span class="social">
                  <List>
                    <List.Item>
                      <List horizontal>
                        <List.Item>
                          <UniversalLink
                            href="https://www.facebook.com/ddpdemocracy/"
                            target="_blank"
                          >
                            <i class="bi bi-facebook"></i>
                          </UniversalLink>
                        </List.Item>
                        <List.Item>
                          <UniversalLink
                            href="https://twitter.com/DDPdemocracy"
                            target="_blank"
                          >
                            <i class="bi bi-twitter-x"></i>
                          </UniversalLink>
                        </List.Item>
                        <List.Item>
                          <UniversalLink
                            href="https://www.instagram.com/ddp_democracy/"
                            target="_blank"
                          >
                            <i class="bi bi-instagram"></i>
                          </UniversalLink>
                        </List.Item>
                        <List.Item>
                          <UniversalLink
                            href="https://www.youtube.com/channel/UCnxCoHzh63EKYQJ7Ug4mxPg?disable_polymer=true"
                            target="_blank"
                          >
                            <i class="bi bi-youtube"></i>
                          </UniversalLink>
                        </List.Item>
                      </List>
                    </List.Item>
                  </List>
                </span>
              </GridColumn>
            </Grid>
          </Container>
        </div>
        <Container layout>
          {intranetHeader ? (
            <IntranetHeader
              pathname={pathname}
              siteLabel={siteLabel}
              token={token}
              siteAction={siteAction}
            />
          ) : (
            <InternetHeader
              pathname={pathname}
              siteLabel={siteLabel}
              token={token}
              siteAction={siteAction}
            />
          )}
        </Container>
      </header>
    </>
  );
};

Header.propTypes = {
  token: PropTypes.string,
  pathname: PropTypes.string.isRequired,
};

Header.defaultProps = {
  token: null,
};

export default Header;
