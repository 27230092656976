import { defineMessages } from 'react-intl';
import { getAvailableFieldsProperty } from './availableFields';

const listingSchemaEnhancer = (schema, intl) => {
  // Define choices directly to verify if they are added correctly

  // Add AvailableFields with test choices
  schema.properties.availableFields = getAvailableFieldsProperty(intl);

  // Add AvailableFields to the default fieldset
  schema.fieldsets[0].fields.push('availableFields');

  return schema;
};

export default listingSchemaEnhancer;

