/**
 * Footer component.
 * @module components/theme/Footer/Footer
 */

import React from 'react';
import {
  Container,
  List,
  Segment,
  Grid,
  GridColumn,
  Image,
} from 'semantic-ui-react';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
import { UniversalLink } from '@plone/volto/components';
import { useGoogleAnalytics } from 'volto-google-analytics';

const messages = defineMessages({
  copyright: {
    id: 'Copyright',
    defaultMessage: 'Copyright',
  },
});

/**
 * Component to display the footer.
 * @function Footer
 * @param {Object} intl Intl object
 * @returns {string} Markup of the component
 */
const Footer = ({ intl }) => {
  useGoogleAnalytics();

  return (
    <div class="footer-container print_hide">
      <Segment
        role="contentinfo"
        vertical
        padded
        inverted
        id="footer"
        aria-label="Footer"
      >
        <Container>
          <div id="footer-main">
            <Grid padded stackable>
              <GridColumn width={4} textAlign="left">
                <div>
                  The Democracy Development Program (DDP) aims to deepen the
                  practice of democracy in South Africa. We build strong and
                  active communities that can hold those in power to account.
                  Since our beginning in 1993 we have achieved this mission.
                </div>
              </GridColumn>
              <GridColumn width={1} textAlign="left"></GridColumn>
              <GridColumn width={2} textAlign="left">
                <List>
                  <List.Header>
                    <h4>Quick links</h4>
                  </List.Header>
                  <List.Content>
                    <List.Item>
                      <UniversalLink href="/about/who-we-are">
                        Who we are
                      </UniversalLink>
                    </List.Item>
                    <List.Item>
                      <UniversalLink href="/our-work">Our Work</UniversalLink>
                    </List.Item>
                    <List.Item>
                      <UniversalLink href="/projects">Projects</UniversalLink>
                    </List.Item>
                    <List.Item>
                      <UniversalLink href="/africa-connect">
                        Africa Connect
                      </UniversalLink>
                    </List.Item>
                    <List.Item>
                      <UniversalLink href="/resources">Resources</UniversalLink>
                    </List.Item>
                    <List.Item>
                      <UniversalLink href="/blog">Blog</UniversalLink>
                    </List.Item>
                  </List.Content>
                </List>
              </GridColumn>
              <GridColumn width={4} textAlign="left">
                <List>
                  <List.Header>
                    <h4>Where we are</h4>
                  </List.Header>
                  <List.Content>
                    <List.Item>
                      <div class="item">
                        32 Dullah Omar Lane (Masonic Grove), DDP House, 2nd
                        Floor, Durban 4001
                      </div>
                      <UniversalLink href="/contact" target="_blank">
                        Contact Us
                      </UniversalLink>
                    </List.Item>
                    <hr />
                    <List.Item>
                      <List horizontal>
                        <List.Item>
                          <UniversalLink
                            href="https://www.facebook.com/ddpdemocracy/"
                            target="_blank"
                          >
                            <i class="bi bi-facebook"></i>
                          </UniversalLink>
                        </List.Item>
                        <List.Item>
                          <UniversalLink
                            href="https://twitter.com/DDPdemocracy"
                            target="_blank"
                          >
                            <i class="bi bi-twitter-x"></i>
                          </UniversalLink>
                        </List.Item>
                        <List.Item>
                          <UniversalLink
                            href="https://www.instagram.com/ddp_democracy/"
                            target="_blank"
                          >
                            <i class="bi bi-instagram"></i>
                          </UniversalLink>
                        </List.Item>
                        <List.Item>
                          <UniversalLink
                            href="https://www.youtube.com/channel/UCnxCoHzh63EKYQJ7Ug4mxPg"
                            target="_blank"
                          >
                            <i class="bi bi-youtube"></i>
                          </UniversalLink>
                        </List.Item>
                        <List.Item>
                          <UniversalLink
                            href="https://www.linkedin.com/company/democracy-development-program/"
                            target="_blank"
                          >
                            <i class="bi bi-linkedin"></i>
                          </UniversalLink>
                        </List.Item>
                        <List.Item>
                          <UniversalLink
                            href="https://www.tiktok.com/@ddpdemocracy"
                            target="_blank"
                          >
                            <i class="bi bi-tiktok"></i>
                          </UniversalLink>
                        </List.Item>
                        <List.Item>
                          <UniversalLink
                            href="https://open.spotify.com/show/20JW0AYTXg8ugE8u3ly92g"
                            target="_blank"
                          >
                            <i class="bi bi-spotify"></i>
                          </UniversalLink>
                        </List.Item>
                      </List>
                    </List.Item>
                    <hr />
                    <List.Header>
                      <h4>Proud partner of</h4>
                    </List.Header>
                    <List.Item>
                      <UniversalLink
                        href="https://www.kas.de/en/web/suedafrika"
                        target="_blank"
                      >
                        <Image
                          src="/siteworkspace/konrad-adenauer-stiftung_logo.svg/@@images/image"
                          fluid
                        />
                      </UniversalLink>
                    </List.Item>
                  </List.Content>
                </List>
              </GridColumn>
              <GridColumn width={1} textAlign="right" verticalAlign="bottom">
                <List>
                  <List.Content>
                    <List.Item>
                      <FormattedMessage
                        id="{copyright} DDP {current_year}"
                        defaultMessage="{copyright} DDP {current_year}"
                        values={{
                          copyright: (
                            <abbr
                              title={intl.formatMessage(messages.copyright)}
                            >
                              ©
                            </abbr>
                          ),
                          current_year: new Date().getFullYear(),
                        }}
                      />
                    </List.Item>
                    <List.Item>
                      <UniversalLink href="#top" class="header-link">
                        <i class="bi bi-arrow-up-circle-fill"></i>
                      </UniversalLink>
                    </List.Item>
                  </List.Content>
                </List>
              </GridColumn>
            </Grid>
          </div>
        </Container>
      </Segment>
      <div>
        <Container textAlign="center">
          <div class="below-footer">
            CMS Website by{' '}
            <UniversalLink href="https://juizi.com" target="_blank">
              Juizi
            </UniversalLink>
          </div>
        </Container>
      </div>
    </div>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
Footer.propTypes = {
  /**
   * i18n object
   */
};

export default injectIntl(Footer);
