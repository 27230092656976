const applyConfig = (config) => {
  config.settings.isMultilingual = false;
  config.settings.supportedLanguages = ['en'];
  config.settings.defaultLanguage = 'en';
  config.blocks.themes = [
    {
      style: {
        '--theme-color': 'white',
        '--theme-high-contrast-color': 'var(--lightgrey)',
        '--theme-foreground-color': 'black',
        '--theme-low-contrast-foreground-color': 'var(--grey)',
      },
      name: 'default',
      label: 'Default',
    },
    {
      style: {
        '--theme-color': 'var(--lightgrey)',
        '--theme-high-contrast-color': 'black',
        '--theme-foreground-color': 'black',
        '--theme-low-contrast-foreground-color': 'var(--lightgrey)',
      },
      name: 'lightgrey',
      label: 'Light Grey',
    },
    {
      style: {
        '--theme-color': 'var(--green)',
        '--theme-high-contrast-color': 'white',
        '--theme-foreground-color': 'white',
        '--theme-low-contrast-foreground-color': 'var(--lightgrey)',
      },
      name: 'green',
      label: 'Green',
    },
  ];

  //Add additional volto-columns-block colours here

  config.blocks.blocksConfig.columnsBlock.available_colors = [
    // '#B80000',
    // '#DB3E00',
    // '#FCCB00',
    // '#008B02',
    // '#006B76',
    // '#1273DE',
    // '#004DCF',
    // '#5300EB',
    '#EFEFEF',
    '#EB9694',
    '#FAD0C3',
    '#FEF3BD',
    '#C1E1C5',
    '#BEDADC',
    '#C4DEF6',
    '#BED3F3',
    '#D4C4FB',
    '#2ab370',
    '#000000',
  ];

  //enable volto-slider-block autoplay
  config.blocks.blocksConfig.slider.enableAutoPlay = true;

  return config;
};

export default applyConfig;
