// src/index.js
import DetailedTemplateComponent from './customizations/components/manage/Blocks/Listing/DetailedTemplateComponent';
import listingSchemaEnhancer from './customizations/components/manage/Blocks/Listing/schemaEnhancer';

import './theme/extras.less'


const applyConfig = (config) => {

  // Override blockSchema with the enhancer
  const originalListingBlockSchema = config.blocks.blocksConfig.listing.blockSchema;
  config.blocks.blocksConfig.listing.blockSchema = (props) => {
    let listing_schema = originalListingBlockSchema(props); // Call the original schema
    if (props.data.variation == 'detailed') {
      listing_schema = listingSchemaEnhancer(listing_schema, props.intl); // Enhance the schema
    };
    return listing_schema;
  };

  // Register the "Detailed Template" as a new variation if not already present
  if (!config.blocks.blocksConfig.listing.variations.some(v => v.id === 'detailed')) {
    config.blocks.blocksConfig.listing.variations.push({
      id: 'detailed',
      title: 'Detailed',
      template: DetailedTemplateComponent,  // The component used for rendering
      isDefault: false,
    });
  }

  return config;
};

export default applyConfig;

